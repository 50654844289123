<template>
  <div class="modal-box">
    <div class="modal-body lz-modal__body">
      <!-- 左面板视图 -->
      <div class="left-panel">
        <div class="content-section">
          <div class="section-view">
            <label style="margin-bottom: 12px" class="section-stick">员工信息</label>
            <el-form ref="form-left1" class="form-view" :model="formData" :rules="formRule" label-width="101px">
              <el-form-item label="考勤酒店：" prop="hotelData">
                <el-select
                  :value="formData.hotelData"
                  value-key="hotelVid"
                  filterable
                  placeholder="请选择考勤酒店"
                  @change="handleHotelSelectChange">
                  <el-option
                    v-for="item in hotelList"
                    :key="item.hotelVid"
                    :label="item.hotelName"
                    :value="item">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="员工：" prop="memberData">
                <el-select
                  :value="formData.memberData"
                  value-key="userId"
                  filterable
                  remote
                  :remote-method="searchMemberDatas"
                  :loading="selectLoading"
                  :loading-text="loadingText"
                  :no-data-text="noDataText"
                  placeholder="请输入关键字搜索"
                  @change="handleMemberSelectChange">
                  <el-option
                    v-for="item in memberList"
                    :key="item.userId"
                    :label="item.userName"
                    :value="item">
                    <span v-if="item.hotelName">{{ item.userName+'-'+item.hotelName }}</span>
                    <span v-else>{{ item.userName }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="员工所属酒店：">
                <el-input :value="userHotelName" disabled></el-input>
              </el-form-item>
              <el-form-item label="职位：" prop="jobId">
                <el-select
                  v-model="formData.jobId"
                  filterable
                  placeholder="请选择员工职位">
                  <el-option
                    v-for="item in jobEnum"
                    :key="item.jobId"
                    :label="item.jobName"
                    :value="item.jobId">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>

          <div class="section-view">
            <label style="margin-top: 32px; margin-bottom: 12px;" class="section-stick">批量设置</label>
            <el-form ref="form-left2" label-width="101px">
              <el-form-item label="选择日期：" :rules="batchDateRules" prop="batchDate">
                <el-date-picker
                  class="form-date-picker"
                  v-model="batchDate"
                  type="daterange"
                  :editable="false"
                  :clearable="false"
                  format="yyyy/MM/dd"
                  :picker-options="getDatePickerOptions()"
                  range-separator="-"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  @change="handleBatchDateChange">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="值班信息：" :rules="batchScheduleRules" prop="batchSchedule">
                <el-popover
                  trigger="click"
                  placement="bottom-start"
                  popper-class="calendar-popover">
                  <div slot="reference">
                    <el-input :value="batchScheduleValue" readonly placeholder="请选择值班信息" suffix-icon="el-icon-arrow-down"></el-input>
                  </div>
                  <cascader-panel-view
                    v-if="scheduleSelectList.length"
                    :options="scheduleSelectList"
                    @change="handleBatchCascaderPanelChange">
                  </cascader-panel-view>
                </el-popover>
              </el-form-item>
              <el-form-item>
                <div class="form-text-action" @click="handleBatchSetClick">批量设置
                  <lz-icon type="element-icons" name="el-icon-arrow-right" :size="12" color="#497CF6"></lz-icon>
                </div>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>

      <!-- 右面板视图 -->
      <div class="right-panel">
        <div class="content-section">
          <div class="section-view">
            <div style="height: 16px; margin-bottom: 12px;" class="display__flex align_items__center justify_content__space_between">
              <label class="section-stick">值班日历</label>
              <color-lump-tips :datas="abnormalMarkEnum"></color-lump-tips>
            </div>
            <calendar-view
              :date="dataDate"
              :current-day="day">
              <template slot-scope="scope">
                <el-popover
                  trigger="click"
                  popper-class="calendar-popover"
                  @show="handlePopoverShow(scope.data.day)"
                  @after-leave="handlePopoverAfterLeave(scope.data.day)">
                  <div slot="reference" :style="{'background-color': attendanceMarkColor(scope.data.day) }" class="calendar-cell-outside">
                    <span>{{ attendanceDesc(scope.data.day) }}</span>
                    <lz-icon type="element-icons" name="el-icon-arrow-down" :size="12" color="#596176"></lz-icon>
                  </div>

                  <cascader-panel-view
                    v-if="scheduleSelectList.length && popoverData[scope.data.day]===true"
                    :options="scheduleSelectList"
                    :values="scheduleCascaderData(scope.data.day)"
                    @change="(data) => handleCascaderPanelChange(scope.data.day, data)">
                  </cascader-panel-view>
                </el-popover>
              </template>
            </calendar-view>
          </div>
          <div style="margin-top: 12px;" class="section-view">
            <el-form ref="form-right" :model="formData" label-width="73px">
              <el-form-item label="上月欠休：" :rules="oweDayRules" prop="oweDays">
                <el-input 
                  class="form-input-number"
                  type="number"
                  v-model="formData.oweDays"
                  :min="0"
                  :max="31"
                  placeholder="请输入上月欠休">
                </el-input>
              </el-form-item>
              <el-form-item label="特殊说明：">
                <el-input
                  type="textarea"
                  v-model="formData.remark"
                  :rows="3"
                  :maxlength="256"
                  placeholder="本月存在异常情况时，请说明">
                </el-input>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
    <div class="lz-modal__footer lz-modal__footer__line">
      <el-button class="lz-modal__footer__button confirm" @click="handleSaveClick">确定</el-button>
      <el-button class="lz-modal__footer__button cancel" @click="handleCloseClick">取消</el-button>
    </div>
  </div>
</template>

<script>

import { mapState, mapGetters, mapActions } from 'vuex'
import CalendarView from '../calendar/index.vue'
import CascaderPanelView from './cascader-panel/cascader-panel.vue'
import scheduleHelper from './schedule-cascader'
import serviceAPI from '../api'
import ColorLumpTips from './color-lump.vue'

/**
 * 考勤管理 - 门店排班编辑
 * @module @/view/attendance
 */
export default {
  name: 'HotelScheduleEdit',
  components: {
    CalendarView,
    CascaderPanelView,
    ColorLumpTips
  },
  props: {
    // 操作类型（1-新增，2-编辑）
    action: {
      type: Number,
      default: 1
    },

    // 数据项 id
    id: Number,

    year: Number,

    month: Number,

    day: Number,

    // 酒店列表数据
    hotelList: Array
  },
  data () {
    return {
      formData: {
        hotelData: null,
        memberData: null,
        jobId: '',
        oweDays: '',
        remark: ''
      },
      // 所属酒店
      userHotelName: '',
      // 排班考勤数据
      scheduleData: [],

      // 成员列表数据源
      selectLoading: false,
      loadingText: '请输入关键字搜索',
      noDataText: ' ',
      loadingTimeout: null,
      memberList: [],
      // 批次日期
      batchDate: null,
      // 排班下拉数据源
      scheduleSelectList: [],
      batchScheduleData: [],
      batchScheduleValue: '',

      popoverData: {},

      formRule: {
        hotelData: [
          { required: true, message: '请选择考勤酒店', trigger: 'change' }
        ],
        memberData: [
          { required: true, message: '请选择员工', trigger: 'change' }
        ],
        jobId: [
          { required: true, message: '请选择员工职位', trigger: 'change' }
        ]
      },
      batchDateRules: {
        message: '请选择日期范围', validator: (rule, value, callback) => {
          if (!this.batchDate || !this.batchDate.length) {
            callback(new Error(rule.message));
          } else {
            callback();
          }
        }
      },
      batchScheduleRules: {
        message: '请选择值班信息', trigger: 'click', validator: (rule, value, callback) => {
          if (!this.batchScheduleData || !this.batchScheduleData.length) {
            callback(new Error(rule.message));
          } else {
            callback();
          }
        }
      },
      oweDayRules: {
        trigger: 'blur', validator: (rule, value, callback) => {
          if (value != '') {
            if (value<0 || value>31) {
              callback(new Error('上月欠休数值范围需为0到31'));
              return;
            } else if (!(/^(([1-9]{1}\d*)|(0{1}))(\.\d{1})?$/.test(value))) {
              callback(new Error('上月欠休最多保留1位小数'));
              return;
            }
          }
          callback();
        }
      }
    }
  },
  computed: {
    ...mapState('attendance', [
      'jobEnum',
      'abnormalMarkEnum'
    ]),

    ...mapGetters('attendance', [
      'scheduleTypeName',
      'attendanceAbnormalMark',
      'isAttendanceAbnormal'
    ]),

    // 是否是编辑操作
    isActionEdit () {
      return this.action === 2;
    },

    // 数据日期
    dataDate () {
      return new Date(this.year, this.month-1, this.day||1);
    }
  },
  created () {
    this.initData();
  },
  methods: {
    ...mapActions('attendance', [
      'getJobSelectList',
      'getWorkShiftSelectList',
      'getAttendanceStateSelectList'
    ]),

    initData () {
      this.getJobSelectList();
      Promise.all([this.getWorkShiftSelectList(), this.getAttendanceStateSelectList()]).then(datas => {
        this.scheduleSelectList = scheduleHelper.processCascaderDataSource(datas);
      });
      if (this.isActionEdit) {
        this.getScheduleDetail();
      }
    },

    /**
     * 设置视图 loading 状态
     */
    viewLoading (loading) {
      this.$emit('loading', loading);
    },

    /**
     * Date picker options
     */
    getDatePickerOptions () {
      let that = this;
      return {
        disabledDate (date) {
          return date.getFullYear()!=that.year || date.getMonth()+1!=that.month;
        }
      }
    },

    /**
     * 考勤标记颜色
     * @param { Number } day 天
     * @returns { String } color 信息
     */
    attendanceMarkColor (day) {
      let data = this.scheduleDataByDay(day);
      if (data && this.isAttendanceAbnormal(data.lateStatus, data.leaveEarlyStatus)) {
        let markData = this.attendanceAbnormalMark(data.lateStatus, data.leaveEarlyStatus);
        return markData.color;
      }
      return '';
    },

    /**
     * 班次/考勤描述信息
     * @param { Number } day 天
     * @returns { String } 描述信息
     */
    attendanceDesc (day) {
      let data = this.scheduleDataByDay(day);
      if (data) {
        return this.scheduleTypeName(data.workShiftType);
      } else {
        return '';
      }
    },

    /**
     * Cascader 考勤数据
     * @param { Array|Null } cascader 数据
     */
    scheduleCascaderData (day) {
      let data = this.scheduleDataByDay(day);
      let values;
      if (data) {
        values = scheduleHelper.parse2CascaderData(data);
      }
      return values;
    },

    /**
     * 获取班次/考勤数据
     * @param { Number } day 天
     * @returns { Object|Null } 班次/考勤数据
     */
    scheduleDataByDay (day) {
      return this.scheduleData.find(item => {
        return Number(item.workShiftDate.split('-')[1]) == day;
      });
    },


    /**
     * 搜素“成员列表”数据
     * @note 添加“防抖”
     * @param { String } 搜索关键字
     */
    searchMemberDatas (keyword) {
      if (keyword) {
        if (this.loadingTimeout) {
          clearTimeout(this.loadingTimeout);
        }
        this.loadingTimeout = setTimeout(() => {
          this.selectLoading = true;
          this.loadingText = '搜索中，请稍候';
          this.noDataText = ' ';
          this.getMemberSelectList(keyword).then(data => {
            this.selectLoading = false;
            if (data.length === 0) {
              this.noDataText = '无匹配数据';
              this.memberList = [];
            } else {
              this.memberList = data;
            }
          }).catch(error => {
            this.selectLoading = false;
            this.noDataText = '系统异常，请稍后再试';
            this.memberList = [];
          });
        }, 600);
      } else {
        this.memberList = [];
      }
    },


    /**
     * 获取门店排班详情
     */
    getScheduleDetail () {
      this.viewLoading(true);
      serviceAPI.getScheduleDetail({ id: this.id }).then(res => {
        this.viewLoading(false);
        if (res.code==200 && res.data) {
          this.processDetailData(res.data);
        } else {
          this.$notice.error(res.message);
        }
      }).catch(error => {
        this.viewLoading(false);
        this.$notice.error('系统异常，请稍后再试');
        console.error('Get schedule detail error: ', error.message);
      });
    },

    /**
     * 处理门店排班详情信息
     */
    processDetailData (data) {
      this.formData.hotelData = {
        hotelVid: data.hotelVid,
        brandCode: data.brandCode
      }
      this.formData.memberData = {
        userId: data.userId
      }
      this.formData.jobId = data.jobId;
      this.formData.oweDays = data.oweDays;
      this.formData.remark = data.remark;
      this.userHotelName = data.userHotelName;
      this.scheduleData = data.details;
      
      // 构造“员工”默认选项列表以回显选中值
      this.memberList = [{
        userId: data.userId,
        userName: data.userName,
        hotelName: data.userHotelName
      }];
    },

    /**
     * 获取成员下拉列表
     */
    getMemberSelectList (name) {
      return new Promise((resolve, reject) => {
        serviceAPI.memberSelectList({ name }).then(res => {
          if (res.code==200 && res.data) {
            resolve(res.data);
          } else {
            reject(new Error(res.message));
          }
        }).catch(error => {
          console.error('Get memebr select list error: ', error.message);
          reject(error);
        });
      });
      
    },

    /**
     * 保存排班/考勤
     */
    saveSchedule () {
      let params = this.processFormData(this.formData);
      this.viewLoading(true);
      serviceAPI.saveHotelSchedule(params).then(res => {
        this.viewLoading(false);
        if (res.code == 200) {
          this.$notice.success('保存排班/考勤成功');

          this.$emit('success');
          this.handleCloseClick();
        } else {
          this.$notice.error(res.message);
        }
      }).catch(error => {
        this.viewLoading(false);
        this.$notice.error('系统异常，请稍后再试');
        console.error('Save hotel schedule error: ', error.message);
      })
    },

    /**
     * 构造表单数据
     * @param { Object } data 原始数据
     * @param { Object } 用于数据请求的表单数据
     */
    processFormData (data) {
      let form = {
        hotelVid: data.hotelData.hotelVid,
        brandCode: data.hotelData.brandCode,
        userId: data.memberData.userId,
        jobId: data.jobId,
        oweDays: data.oweDays || 0,
        remark: data.remark,
        workShiftDate: this.year + '-' + (this.month>=10 ? this.month : `0${this.month}`)
      }
      form.details = this.scheduleData;
      if (this.isActionEdit) {
        form.id = this.id;
      }
      return form;
    },


    /**
     * “确定”点击事件
     */
    handleSaveClick () {
      this.$refs['form-left1'].validate(valid1 => {
        this.$refs['form-right'].validate(valid2 => {
          if (valid1 && valid2) {
            this.saveSchedule();
          }
        });
      });
    },

    /**
     * “取消”点击事件
     */
    handleCloseClick () {
      this.$emit('input', false);
    },


    /** 员工信息事件相关 **/
    /**
     * “考勤酒店”下拉选择改变事件
     */
    handleHotelSelectChange (value) {
      this.formData.hotelData = {
        hotelVid: value.hotelVid,
        brandCode: value.brandCode
      }
    },

    /**
     * “员工”下拉选择事件
     */
    handleMemberSelectChange (value) {
      this.formData.memberData = value;
      this.userHotelName = value.hotelName;
      this.formData.jobId = value.jobId || '';
    },

    
    /** 批量设置相关 **/
    /**
     * 批量设置 - 选择日期改变事件
     */
    handleBatchDateChange (value) {
      this.$refs['form-left2'].validateField('batchDate');
    },

    /**
     * “批量设置”点击事件
     */
    handleBatchSetClick () {
      this.$refs['form-left2'].validateField('batchDate', dateError => {
        this.$refs['form-left2'].validateField('batchSchedule', scheduleError => {
          if (!dateError && !scheduleError) {
            this.processBatchScheduleData();
          }
        });
      });
    },

    /**
     * “批量设置”数据处理
     */
    processBatchScheduleData () {
      let startDay = this.batchDate[0].getDate();
      let endDay = this.batchDate[1].getDate();
      let scheduleData = scheduleHelper.parse2ProtocolData(this.batchScheduleData);
      let month = this.month>=10 ? this.month : `0${this.month}`;
      for (let i = startDay; i <= endDay; i++) {
        let dayData = this.scheduleDataByDay(i);
        if (dayData) {
          // 更新数据
          dayData = Object.assign(dayData, scheduleData);
        } else {
          // 插入数据
          let dateStr = month + '-' + (i>=10 ? i : `0${i}`);
          let tmpData = { ...scheduleData };
          tmpData.workShiftDate = dateStr;
          this.scheduleData.push(tmpData);
        }
      }
    },

    /**
     * Batch cascader panel change 事件
     */
    handleBatchCascaderPanelChange (data) {
      this.batchScheduleData = data;
      this.batchScheduleValue = this.scheduleTypeName(scheduleHelper.parseCascader2ProtocolValue(data[0]));
      this.$refs['form-left2'].validateField('batchSchedule');
    },


    /**
     * Popover cascader show 事件
     * @param { Number } day 天
     */
    handlePopoverShow (day) {
      this.$set(this.popoverData, day, true);
    },

    /**
     * Popover cascader after leave 事件
     * @param { Number } day 天
     */
    handlePopoverAfterLeave (day) {
      this.popoverData.day = false;
      this.$set(this.popoverData, day, false);
    },

    /**
     * Cascader panel change 事件
     * @param { Number } day 天
     * @param { Array } data 班次/考勤数据
     */
    handleCascaderPanelChange (day, data) {
      let dayData = this.scheduleDataByDay(day);
      let scheduleData = scheduleHelper.parse2ProtocolData(data);
      if (dayData) {
        dayData = Object.assign(dayData, scheduleData);
      } else {
        let dateStr = (this.month>=10 ? this.month : `0${this.month}`) + '-' + (day>=10 ? day : `0${day}`);
        scheduleData.workShiftDate = dateStr;
        this.scheduleData.push(scheduleData);
      }
    }
  }
}
</script>

<style lang="scss">
  .calendar-popover {
    padding: 0;
  }
</style>

<style lang="scss" scoped>
  @import "@/style/const";
  @import "@/style/mixins";

  .modal-body {
    height: calc(100% - 52px);
  }

  .left-panel .content-section,
  .right-panel .content-section {
    padding: 16px;
    @include section-stick($width: 3px);
  }

  .left-panel {
    width: 38%;
    height: 100%;
    display: inline-block;
    vertical-align: top;

    .form-view .el-form-item {
      margin-bottom: 18px;
    }

    .form-date-picker {
      width: 100%;

      ::v-deep .el-icon-date,
      ::v-deep .el-range-separator {
        line-height: 24px;
      }

      ::v-deep .el-range-separator {
        padding: 0 2px;
      }

      ::v-deep .el-range__close-icon {
        display: none;
      }

      ::v-deep .el-range-input {
        width: 40%;
      }
    }

    .form-text-action {
      text-align: right;
      color: $theme-color;
      line-height: 1;
      cursor: pointer;
    }
  }

  .right-panel {
    width: 62%;
    height: 100%;
    border-left: 1px solid $border-color;
    display: inline-block;
    vertical-align: top;

    .form-input-number {
      width: 204px;
      @include input-number-none-button;
    }

    .calendar-box {
      .calendar-cell-outside {
        height: 23px;
        line-height: 23px;
        padding: 0 10px;
        background-color: rgba(222, 227, 238, 0.16);
        position: relative;
        cursor: pointer;
      }

      .calendar-cell-outside i {
        position: absolute;
        top: 6px;
        right: 8px;
      }

      .calendar-cell-outside span {
        color: rgba(71, 79, 100, 0.8);
        font-size: 12px;
      }
    }
  }
</style>
