let uid = 0;

export default class Node {
  constructor(data, parentNode, checkedValue) {
    this.data = data;
    this.parent = parentNode || null;
    this.level = !this.parent ? 1 : this.parent.level + 1;
    this.uid = uid++;

    this.initState(checkedValue);
    this.initChildren(checkedValue);
  }

  initState (checkedValue) {
    this.value = this.data['value'];
    this.label = this.data['label'];
    this.multiple = this.data['multiple'];
    this.checked = false;
    checkedValue.forEach(value => {
      if (this.parent && this.parent.value==value.split('__')[0] && this.value==value.split('__')[1]) {
        this.checked = true;
      }
    });
    this.pathNodes = this.calculatePathNodes();
  }

  initChildren (checkedValue) {
    const childrenData = this.data['children'];
    this.hasChildren = Array.isArray(childrenData);
    this.children = (childrenData || []).map(child => new Node(child, this, checkedValue));
  }

  get isLeaf () {
    return !this.hasChildren;
  }

  calculatePathNodes () {
    const nodes = [this];
    let parent = this.parent;

    while (parent) {
      nodes.unshift(parent);
      parent = parent.parent;
    }

    return nodes;
  }
}
