var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page-box container-box" },
    [
      _vm.isPageViewable
        ? _c("div", { staticClass: "page-panel" }, [
            _c("div", { ref: "listBox", staticClass: "data-list" }, [
              _c(
                "div",
                { ref: "listHead", staticClass: "data-list__header" },
                [
                  _c(
                    "el-form",
                    {
                      staticClass: "lz-form--inline",
                      attrs: { model: _vm.filterForm, "label-width": "45px" }
                    },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 16 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { sm: 10, md: 8, lg: 6, xl: 4 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "酒店：" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        filterable: "",
                                        clearable: "",
                                        placeholder: "选择考勤酒店"
                                      },
                                      model: {
                                        value: _vm.filterForm.hotelVid,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.filterForm,
                                            "hotelVid",
                                            $$v
                                          )
                                        },
                                        expression: "filterForm.hotelVid"
                                      }
                                    },
                                    _vm._l(_vm.hotelList, function(item) {
                                      return _c("el-option", {
                                        key: item.hotelVid,
                                        attrs: {
                                          label: item.hotelName,
                                          value: item.hotelVid
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { sm: 10, md: 8, lg: 6, xl: 4 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "日期：" } },
                                [
                                  _c("el-date-picker", {
                                    attrs: {
                                      type: "month",
                                      "picker-options": _vm.datePickerOptions,
                                      clearable: false,
                                      placeholder: "选择考勤月份"
                                    },
                                    model: {
                                      value: _vm.filterForm.date,
                                      callback: function($$v) {
                                        _vm.$set(_vm.filterForm, "date", $$v)
                                      },
                                      expression: "filterForm.date"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { sm: 4, md: 8, lg: 6, xl: 4 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { "label-width": "0" } },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "action-button",
                                      attrs: { type: "primary" },
                                      on: { click: _vm.handleQueryClick }
                                    },
                                    [_vm._v("查询")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("div", { staticClass: "data-list__separator" })
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.tableLoading,
                      expression: "tableLoading"
                    }
                  ],
                  staticClass: "data-list__body"
                },
                [
                  _c(
                    "div",
                    {
                      ref: "listAction",
                      staticClass:
                        "data-list__action display__flex justify_content__space_between align_items__center"
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "icon-view display__flex align_items__center"
                        },
                        [
                          _c(
                            "el-popover",
                            { attrs: { trigger: "hover" } },
                            [
                              _c("lz-icon", {
                                staticClass: "icon",
                                attrs: {
                                  slot: "reference",
                                  type: "iconfont",
                                  name: "lzicon-chat_dot_round",
                                  size: 13
                                },
                                slot: "reference"
                              }),
                              _c("div", [
                                _vm._v("提示：点击行日期数据可进行编辑")
                              ])
                            ],
                            1
                          ),
                          _c("color-lump-tips", {
                            attrs: { datas: _vm.abnormalMarkEnum }
                          })
                        ],
                        1
                      ),
                      _c("div", [
                        _vm.isScheduleEditable
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "action-button__icon--basic action-button--primary",
                                on: { click: _vm.handleAddSchduleClick }
                              },
                              [
                                _c("lz-icon", {
                                  staticStyle: {
                                    "margin-right": "6px",
                                    "line-height": "30px"
                                  },
                                  attrs: {
                                    type: "iconfont",
                                    name: "lzicon-add"
                                  }
                                }),
                                _c("span", { staticClass: "suffix-text" }, [
                                  _vm._v("新增排班")
                                ])
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.isScheduleSubmitable
                          ? _c(
                              "div",
                              {
                                staticClass: "action-button__icon--basic",
                                staticStyle: { "margin-left": "8px" },
                                on: { click: _vm.handleSubmitClick }
                              },
                              [
                                _c("span", { staticClass: "suffix-text" }, [
                                  _vm._v("提交到集团")
                                ])
                              ]
                            )
                          : _vm._e()
                      ])
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "data-list__table" },
                    [
                      _c(
                        "el-table",
                        {
                          ref: "table",
                          attrs: {
                            "empty-text": _vm.noDataText,
                            data: _vm.tableDatas,
                            "max-height": _vm.tableMaxHeight,
                            "cell-style": _vm.getCellStyle,
                            border: ""
                          },
                          on: {
                            "cell-mouse-enter": _vm.handleTableCellMouseEnter,
                            "cell-mouse-leave": _vm.handleTableCellMouseLeave,
                            "cell-click": _vm.handleTableCellClick,
                            select: _vm.handleTableSelect,
                            "select-all": _vm.handleTableSelectAll
                          }
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              type: "selection",
                              selectable: _vm.tableColumnSelectable,
                              align: "center",
                              fixed: "",
                              width: "48"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "hotelName",
                              label: "考勤酒店",
                              align: "center",
                              fixed: "",
                              "min-width": "150"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "员工",
                              align: "center",
                              fixed: "",
                              "min-width": "100"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      !scope.row.remark
                                        ? _c("span", [
                                            _vm._v(_vm._s(scope.row.userName))
                                          ])
                                        : _c(
                                            "el-popover",
                                            { attrs: { trigger: "hover" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  attrs: { slot: "reference" },
                                                  slot: "reference"
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "vertical-align":
                                                          "middle"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          scope.row.userName
                                                        )
                                                      )
                                                    ]
                                                  ),
                                                  _c("lz-icon", {
                                                    staticClass:
                                                      "cell-tips__icon",
                                                    attrs: {
                                                      type: "iconfont",
                                                      name: "lzicon-warning",
                                                      size: 14
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "max-width": "300px",
                                                    "white-space": "normal"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(scope.row.remark)
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              4052218829
                            )
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "jobName",
                              label: "职位",
                              align: "center",
                              "min-width": "100"
                            }
                          }),
                          _vm._l(_vm.filterDays, function(index) {
                            return _c("el-table-column", {
                              key: index,
                              attrs: {
                                prop: "date_" + index,
                                label: _vm.formatHeaderDateColumn(index),
                                align: "center",
                                "min-width": "80"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        _vm.checkDateColumnData(
                                          scope.row,
                                          index
                                        )
                                          ? _c("div", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.getDateAttendanceDesc(
                                                      scope.row.id,
                                                      index
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          : _vm.isScheduleEditable &&
                                            scope.row["editing_" + index]
                                          ? _c(
                                              "div",
                                              [
                                                _c("lz-icon", {
                                                  attrs: {
                                                    type: "iconfont",
                                                    name: "lzicon-edit",
                                                    color: "#497CF6",
                                                    size: 16
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "foreShiftCount",
                              label: "早班",
                              align: "center",
                              "min-width": "80"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "middleShiftCount",
                              label: "中班",
                              align: "center",
                              "min-width": "80"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "nightShiftCount",
                              label: "晚班",
                              align: "center",
                              "min-width": "80"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "administrativeShiftCount",
                              label: "行政班",
                              align: "center",
                              "min-width": "80"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "restCount",
                              label: "休息",
                              align: "center",
                              "min-width": "80"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "lateCount",
                              label: "迟到",
                              align: "center",
                              "min-width": "80"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "leaveEarlyCount",
                              label: "早退",
                              align: "center",
                              "min-width": "80"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "absentCount",
                              label: "旷工",
                              align: "center",
                              "min-width": "80"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "askOffCount",
                              label: "请假",
                              align: "center",
                              "min-width": "80"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "oweDays",
                              label: "上月欠休",
                              align: "center",
                              "min-width": "80"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "notWorkCount",
                              label: "缺勤数",
                              align: "center",
                              "min-width": "80"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "workCount",
                              label: "出勤数",
                              align: "center",
                              "min-width": "80"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "上传状态",
                              align: "center",
                              "min-width": "80"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c(
                                        "lz-tag",
                                        {
                                          attrs: {
                                            type: "dot",
                                            color:
                                              scope.row.submitStatus == 1
                                                ? "green"
                                                : "gray"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              scope.row.submitStatus == 1
                                                ? "已提交"
                                                : "未提交"
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              1506598573
                            )
                          }),
                          _vm.isScheduleEditable || _vm.isScheduleSubmitable
                            ? _c("el-table-column", {
                                attrs: {
                                  label: "操作",
                                  align: "center",
                                  fixed: "right",
                                  width: "80"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          _vm.tableRowRevokeSubmitable(
                                            scope.row
                                          )
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "table-operate-btn",
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.handleRevokeSubmitClick(
                                                        scope.$index,
                                                        scope.row.id
                                                      )
                                                    }
                                                  }
                                                },
                                                [_vm._v("撤销提交")]
                                              )
                                            : _vm.tableRowDeletable(scope.row)
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "table-operate-btn",
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.handleDeleteClick(
                                                        scope.$index,
                                                        scope.row.id
                                                      )
                                                    }
                                                  }
                                                },
                                                [_vm._v("删除")]
                                              )
                                            : _vm._e()
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  409488946
                                )
                              })
                            : _vm._e()
                        ],
                        2
                      )
                    ],
                    1
                  )
                ]
              )
            ])
          ])
        : _vm._e(),
      _c("lz-dialog", {
        attrs: {
          name: "revoke",
          width: "360px",
          title: _vm.dialogConfig.title,
          confirmButtonText: "确认",
          "mask-closable": !_vm.dialogLoading,
          "loading-enable": "",
          loading: _vm.dialogLoading
        },
        on: { confirm: _vm.handleRevokeDialogConfirm },
        model: {
          value: _vm.showRevokeDialog,
          callback: function($$v) {
            _vm.showRevokeDialog = $$v
          },
          expression: "showRevokeDialog"
        }
      }),
      _c("lz-dialog", {
        attrs: {
          name: "delete-attendance",
          width: "360px",
          title: _vm.dialogConfig.title,
          confirmButtonText: "确认",
          "mask-closable": !_vm.dialogLoading,
          "loading-enable": "",
          loading: _vm.dialogLoading
        },
        on: { confirm: _vm.handleDeleteDialogConfirm },
        model: {
          value: _vm.showDeleteDialog,
          callback: function($$v) {
            _vm.showDeleteDialog = $$v
          },
          expression: "showDeleteDialog"
        }
      }),
      _c(
        "lz-modal",
        {
          attrs: {
            name: "edit-attendance",
            width: "880px",
            title: _vm.modalConfig.title,
            "mask-closable": false,
            "loading-enable": "",
            loading: _vm.modalLoading,
            "footer-hide": ""
          },
          model: {
            value: _vm.showModal,
            callback: function($$v) {
              _vm.showModal = $$v
            },
            expression: "showModal"
          }
        },
        [
          _c(
            "div",
            { attrs: { slot: "body" }, slot: "body" },
            [
              _c(
                "transition",
                { attrs: { name: "fade" } },
                [
                  _vm.showModal
                    ? _c("edit-view", {
                        attrs: {
                          action: _vm.modalConfig.action,
                          id: _vm.modalConfig.data.id,
                          year: _vm.modalConfig.data.year,
                          month: _vm.modalConfig.data.month,
                          day: _vm.modalConfig.data.day,
                          hotelList: _vm.hotelList
                        },
                        on: {
                          loading: loading => (_vm.modalLoading = loading),
                          success: _vm.handleEditSuccessCallback
                        },
                        model: {
                          value: _vm.showModal,
                          callback: function($$v) {
                            _vm.showModal = $$v
                          },
                          expression: "showModal"
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }