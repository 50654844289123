<template>
  <li
    class="cascader-menu__item"
    :class="{ 'in-active-path': inActivePath, 'actived': checkedValue==node.value, 'checked': node.checked }"
    @click="handleNodeClick(node)">
    <el-checkbox v-if="node.multiple" :key="node.parent.value + '_' + node.value" v-model="node.checked" @change="handleMultiCheckChange"></el-checkbox>
    <el-radio v-else :value="checkedValue" :label="node.value" @change="handleCheckChange(node.value)"><span></span></el-radio>
    <span class="cascader-node__label">{{ node.label }}</span>
    <i v-if="node.hasChildren" class="el-icon-arrow-right cascader-node__suffix"></i>
  </li>
</template>

<script>
export default {
  name: 'ScheduleCascaderNode',
  props: {
    node: Object,

    activePath: Array,

    checkedValue: String
  },
  computed: {
    inActivePath() {
      return this.isInPath(this.activePath);
    },
  },
  methods: {
    isInPath (pathNodes) {
      const selectedPathNode = pathNodes[this.node.level - 1] || {};
      return selectedPathNode.uid === this.node.uid;
    },

    /**
     * node 节点点击事件
     * @param { Object } node 节点对象
     */
    handleNodeClick (node) {
      this.$emit('active', node);
    },

    /**
     * 选择改变事件
     */
    handleCheckChange (value) {
      this.$emit('check-change', this.node, value);
      this.handleNodeClick(this.node);
    },

    /**
     * 多选改变事件
     * @param { Boolean } 
     */
    handleMultiCheckChange (value) {
      this.$emit('multi-check-change', this.node, value);
    }
  }
}
</script>

<style lang="scss" scoped>
  .cascader-menu__item {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-left: 20px;
    padding-right: 20px;
    height: 32px;
    line-height: 32px;

    &:hover {
      background-color: #F5F7FA;
    }

    &.in-active-path {
      font-weight: bold;
    }

    &.actived,
    &.checked {
      color: #497CF6;
    }

    .cascader-node__label {
      flex-grow: 1;
      padding: 0 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .cascader-node__suffix {
      position: absolute;
      right: 10px;
    }

    .el-radio {
      margin-right: 0;
    }

    ::v-deep .el-radio .el-radio__label {
      padding-left: 0;
    }
  }
</style>
