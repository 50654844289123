var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "li",
    {
      staticClass: "cascader-menu__item",
      class: {
        "in-active-path": _vm.inActivePath,
        actived: _vm.checkedValue == _vm.node.value,
        checked: _vm.node.checked
      },
      on: {
        click: function($event) {
          return _vm.handleNodeClick(_vm.node)
        }
      }
    },
    [
      _vm.node.multiple
        ? _c("el-checkbox", {
            key: _vm.node.parent.value + "_" + _vm.node.value,
            on: { change: _vm.handleMultiCheckChange },
            model: {
              value: _vm.node.checked,
              callback: function($$v) {
                _vm.$set(_vm.node, "checked", $$v)
              },
              expression: "node.checked"
            }
          })
        : _c(
            "el-radio",
            {
              attrs: { value: _vm.checkedValue, label: _vm.node.value },
              on: {
                change: function($event) {
                  return _vm.handleCheckChange(_vm.node.value)
                }
              }
            },
            [_c("span")]
          ),
      _c("span", { staticClass: "cascader-node__label" }, [
        _vm._v(_vm._s(_vm.node.label))
      ]),
      _vm.node.hasChildren
        ? _c("i", { staticClass: "el-icon-arrow-right cascader-node__suffix" })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }