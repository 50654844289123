const SCHEDULE_PREFIX = 'shcedule_';
const ATTENDANCE_PREFIX = 'attendance_';

/**
 * 处理 cascader 组件数据源
 * @param { Array } datas 班次/考勤枚举数据
 * @returns { Array } 处理后的数据源
 */
function processCascaderDataSource (datas) {
  let suffixDatas = [];
  datas[1].forEach(item => {
    suffixDatas.push({
      label: item.attendanceName,
      value: ATTENDANCE_PREFIX + item.attendanceStatus,
      multiple: true
    });
  });
  let combinedData = datas[0].map(item => {
    let data = {
      label: item.workShiftName,
      value: SCHEDULE_PREFIX + item.workShiftType
    }
    if (item.workShiftType>=0 && item.workShiftType<=3) {
      data.children = suffixDatas;
    }
    return data;
  });
  return combinedData;
}

/**
 * 解析 cascader 值为协议值
 */
function parseCascader2ProtocolValue (value) {
  return value.split('_')[1];
}

/**
 * 处理 cascader 数据源为协议数据
 * @param { Array } data cascader 数据
 * @returns { Object } 协议数据
 */
function parse2ProtocolData (data) {
  let prefixValue = data[0].split('_')[1],
      suffixData = data.length>1 ? data[1] : null;
  let scheduleData = {
    workShiftType: prefixValue,
    lateStatus: 0,
    leaveEarlyStatus: 0
  };
  if (suffixData) {
    suffixData.forEach(item => {
      let value = item.split('_')[1];
      if (_isAttendanceLate(value)) {
        scheduleData.lateStatus = 1;
      }
      if (_isAttendanceLeaveEarly(value)) {
        scheduleData.leaveEarlyStatus = 1;
      }
    });
  }
  return scheduleData;
}

/**
 * 处理协议数据为 cascader 数据
 * @param { Object } data 协议数据
 * @returns { Array } cascader 数据
 */
function parse2CascaderData (data) {
  let cascaderDatas = [];
  cascaderDatas.push({
    value: SCHEDULE_PREFIX +  data.workShiftType
  });
  let suffixData = [];
  if (data.lateStatus == 1) {
    suffixData.push({
      value: ATTENDANCE_PREFIX + 0
    });
  }
  if (data.leaveEarlyStatus == 1) {
    suffixData.push({
      value: ATTENDANCE_PREFIX + 1
    });
  }
  if (suffixData.length) {
    cascaderDatas.push(suffixData);
  }
  return cascaderDatas;
}


/**
 * 是否是“考勤”迟到
 */
function _isAttendanceLate (value) {
  return value == 0;
}

function _isAttendanceLeaveEarly (value) {
  return value == 1;
}


export default {
  processCascaderDataSource,
  parseCascader2ProtocolValue,
  parse2ProtocolData,
  parse2CascaderData
}
