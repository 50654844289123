<template>
  <div class="cascader-panel">
    <div v-for="(node, index) in menus" :key="index" class="cascader-menu">
      <div class="cascader-menu__wrap">
        <ul class="cascader-menu__list">
          <cascader-node
            v-for="childNode in node"
            :key="childNode.value"
            :node="childNode"
            :active-path="activePath"
            :checked-value="selectedValue"
            @active="handleNodeActiveCallback"
            @check-change="handleCheckChangeCallback"
            @multi-check-change="handleMultiCheckChangeCallback">
          </cascader-node>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>

import Node from './node'
import CascaderNode from './cascader-node.vue'

/**
 * 班次/考勤选择面板
 * @note 暂支持两级
 * @module @/view/attendance
 */
export default {
  name: 'ScheduleCascaderPanel',
  components: {
    CascaderNode
  },
  props: {
    options: {
      type: Array
    },

    values: {
      type: Array
    }
  },
  data () {
    return {
      menus: [],
      activePath: [],
      selectedData: null,
      selectedValue: null,
      checkedData: [],
      checkedValue: []
    }
  },
  mounted () {
    if (this.values) {
      this.initValues(this.values);
    }
    this.initData();
  },
  methods: {
    initData () {
      let checkedValue = [];
      if (this.checkedValue.length) {
        checkedValue = this.checkedValue.map(value => this.selectedValue + '__' + value);
      }
      let nodes = this.options.map(item => new Node(item, null, checkedValue));
      this.menus = [nodes];
    },

    initValues (values) {
      let prefixData = values[0];
      let suffixData = values.length>1 ? values[1] : null;
      this.selectedData = prefixData;
      this.selectedValue = prefixData.value;
      if (suffixData) {
        this.checkedData = suffixData;
        this.checkedValue = suffixData.map(item => item.value); 
      }
    },

    /**
     * Node 节点点击事件
     * @param { Object } node 节点对象
     */
    handleNodeActiveCallback (node) {
      let level = node.level;
      let childNodes = node.children;
      let activePath = this.activePath.slice(0, level-1);
      let menus = this.menus.slice(0, level);
      if (!node.isLeaf) {
        activePath.push(node);
        menus.push(childNodes);
      }
      this.activePath = activePath;
      this.menus = menus;
    },

    /**
     * Check change 事件
     */
    handleCheckChangeCallback (node, value) {
      if (value != this.selectedValue) {
        if (this.selectedValue) {
          let menu = this.menus[node.level - 1];
          let selectedParentNode = menu.find(item => item.value == this.selectedValue);
          selectedParentNode.children.forEach(childNode => {
            childNode.checked = false;
          });
        }
        this.selectedData = node.data;
        this.selectedValue = value;
        this.checkedData = [];
        this.checkedValue = [];

        this.$emit('change', [this.selectedValue]);
      }
    },

    /**
     * Multi check change 事件
     */
    handleMultiCheckChangeCallback (node, value) {
      if (value) {
        let parentNode = node.parent;
        if (parentNode.value != this.selectedValue) {
          if (this.selectedValue) {
            let menu = this.menus[parentNode.level - 1];
            let selectedParentNode = menu.find(item => item.value == this.selectedValue);
            selectedParentNode.children.forEach(childNode => {
              childNode.checked = false;
            });
          }
          this.selectedData = parentNode.data;
          this.selectedValue = parentNode.value;
          this.checkedData = [];
          this.checkedValue = [];
        }
        this.checkedData.push(node.data);
        this.checkedValue.push(node.value);
      } else {
        let index = this.checkedValue.findIndex(value => value == node.value);
        this.checkedData.splice(index, 1);
        this.checkedValue.splice(index, 1);
      }

      this.$emit('change', [this.selectedValue, this.checkedValue]);
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "@/style/const";

  .cascader-panel {
    display: flex;
  }

  .cascader-menu {
    min-width: 180px;
    padding: 6px 0;
  }

  .cascader-menu + .cascader-menu {
    border-left: 1px solid $border-color;
  }

  .cascader-menu__wrap {
    height: 200px;
    overflow: auto;
  }
</style>
